
import { defineComponent, PropType } from "vue";
import {
  getSettingsTableAppearanceInitDto,
  SettingsTableAppearanceDto,
} from "@/modules/settings-appearance/helpers";
import { copy, TABLE_STYLES, TInputComponent } from "table";

export default defineComponent({
  name: "SettingsTableAppearance",
  components: {
    TInputComponent,
  },
  props: {
    data: {
      type: Object as PropType<SettingsTableAppearanceDto>,
      default: () => getSettingsTableAppearanceInitDto(),
    },
  },
  emits: ["change"],
  setup() {
    return {};
  },
  data() {
    return {
      currentData: copy(this.data),
      inputs: [
        {
          type: "color",
          key: "thBackground",
          caption: "Цвет фона заголовка",
          defaultValue: TABLE_STYLES.th.background,
        },
        {
          type: "color",
          key: "tdBackground",
          caption: "Цвет фона для данных",
          defaultValue: TABLE_STYLES.row.background,
        },
        {
          type: "color",
          key: "headerColor",
          caption: "Цвет шрифта для заголовка",
          defaultValue: TABLE_STYLES.header.color,
        },
        {
          type: "color",
          key: "trColor",
          caption: "Цвет шрифта для данных",
          defaultValue: TABLE_STYLES.row.color,
        },
        {
          type: "color",
          key: "borderColor",
          caption: "Цвет границ между ячейками",
          defaultValue: TABLE_STYLES.cell["border-color"],
        },
        {
          type: "color",
          key: "linkColor",
          caption: "Цвет шрифта для ссылок",
          defaultValue: TABLE_STYLES["*|*:link"].color,
        },
        {
          type: "color",
          key: "visitedColor",
          caption: "Цвет шрифта для посещённых ссылок",
          defaultValue: TABLE_STYLES["*|*:visited"].color,
        },
        {
          type: "color",
          key: "selectedHeaderBackground",
          caption: "Цвет фона для выделенного заголовка",
          defaultValue: TABLE_STYLES.selectedHeader.background,
        },
        {
          type: "color",
          key: "selectedCellBackground1",
          caption: "Цвет фона для выделенной ячейки",
          defaultValue: TABLE_STYLES.selectedCell.background,
        },
        {
          type: "color",
          key: "selectedCellBackground2",
          caption:
            "Цвет фона для выделенной ячейки при выделении нескольких ячеек",
          defaultValue: TABLE_STYLES.selectedCell2.background,
        },
        {
          type: "color",
          key: "selectedCellBorderColor1",
          caption: "Цвет границ для выделенной ячейки",
          defaultValue: TABLE_STYLES.selectedCell["border-color"],
        },
        {
          type: "color",
          key: "selectedCellBorderColor2",
          caption:
            "Цвет границ для выделенной ячейки при выделении нескольких ячеек",
          defaultValue: TABLE_STYLES.selectedCell2["border-color"],
        },
        {
          type: "text",
          key: "cellHeight",
          caption: "Длина клетки",
          defaultValue: TABLE_STYLES.cellDiv.height,
        },
        {
          type: "text",
          key: "paddingHorizontalCell",
          caption: "Горизонтальные отступы от контента в клетке",
          defaultValue: TABLE_STYLES.cellDiv["padding-left"],
        },
        {
          type: "text",
          key: "cellFontSize",
          caption: "Размер шрифта в ячейках",
          defaultValue: TABLE_STYLES.cell["font-size"],
        },
        {
          type: "text",
          key: "headerFontSize",
          caption: "Размер шрифта в заголовках",
          defaultValue: TABLE_STYLES.header["font-size"],
        },
        {
          type: "text",
          key: "headerHeight",
          caption: "Минимальная высота заголовков",
          defaultValue: TABLE_STYLES.header.height,
        },
      ] as Array<{
        type: "color" | "text";
        key: keyof SettingsTableAppearanceDto;
        caption: string;
        defaultValue: string;
      }>,
    };
  },
  computed: {},
  watch: {
    currentData: {
      handler() {
        this.$emit("change", copy(this.currentData));
      },
      deep: true,
    },
  },
  created() {},
  methods: {},
});
