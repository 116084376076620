
import { defineComponent } from "vue";
import SettingsTableAppearance from "@/modules/settings-appearance/components/SettingsTableAppearance.vue";
import { SettingsTableAppearanceDto } from "@/modules/settings-appearance/helpers";
import {
  currentSettings,
  initUserSettings,
} from "@/services/UserSettingsService/helpers";
import { USER_SETTINGS_KEYS } from "@/services/UserSettingsService/consts";
import { UserSettingsService } from "@/services/UserSettingsService/UserSettingsService";
import { ButtonComponent, Logger, NotificationsService } from "table";
import { ApiHelper } from "table";

interface CurrentData {
  tableAppearance: SettingsTableAppearanceDto;
}

export default defineComponent({
  name: "SettingsAppearance",
  components: {
    ButtonComponent,
    SettingsTableAppearance,
  },
  props: {},
  emits: [],
  setup() {
    return {};
  },
  data() {
    return {
      currentData: null as CurrentData | null,
    };
  },
  computed: {},
  watch: {},
  async created() {
    this.currentData = {
      tableAppearance: await currentSettings.getTableAppearanceDto(),
    };
  },
  methods: {
    onTableAppearanceChange(tableAppearance: SettingsTableAppearanceDto) {
      this.currentData!.tableAppearance = tableAppearance;
    },
    async onSave() {
      try {
        await UserSettingsService.set(
          USER_SETTINGS_KEYS.TABLE_APPEARANCE,
          this.currentData!.tableAppearance,
        );
        await initUserSettings();
        NotificationsService.send({
          type: "success",
          title: "Сохранение настроек",
          text: "Успешно сохранено",
          duration: 5000,
        });
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "При сохранении настроек произошла ошибка",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
      }
    },
  },
});
